html {
  --imp-text-color: #c770f0;
  font-size: 20px;
}
.purple {
  color: var(--imp-text-color) !important;
}

body {
  overflow-x: hidden;
}
button:focus {
  box-shadow: none !important;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(178, 121, 216, 0.959);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(222, 130, 235, 0.911);
  border-radius: 12px;
}

@media only screen and (width < 1000px) {
  html {
    font-size: 16px;
  }
}
@media only screen and (width < 800px) {
  html {
    font-size: 11px;
  }
}

@media only screen and (width < 500px) {
  html {
    font-size: 8px;
  }
}
@tailwind base;
@tailwind components;
@tailwind utilities;

.no-visible-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
}

.no-visible-scrollbar::-webkit-scrollbar {
  display: none;
}
